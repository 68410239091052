@import "./Mixins.scss";

$colors: (
  "white": "#ffffff",
  "cream": "#f9f5ef",
  "grey": "#808080",
  "hoverWhite": var(--hoverWhite),
  "black": "#000000",
  "yellow": "#DABE5D",
  "blue": "#40657D",
  "hoverBlue": "#003d64",
  "cyan": "#495859",
  "mint": "#A9FFCB",
  "red": "#D85028",
  "hoverRed": var(--hoverRed),
  "gold": "#DCB964",
  "brown": "#624C42",
  "hoverBrown": var(--hoverBrown),
  "tan": "#E8D7C0"
);

$sides: (top, bottom, left, right);

$pixel: 0;
@while $pixel <= 1000 {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$pixel} {
      margin-#{$side}: #{$pixel}px !important;
    }

    .-m-#{str-slice($side, 0, 1)}-#{$pixel} {
      margin-#{$side}: -#{$pixel}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$pixel} {
      padding-#{$side}: #{$pixel}px !important;
    }
  }
  .m-a-#{$pixel} {
    margin: #{$pixel}px !important;
  }
  .m-x-#{$pixel} {
    margin-left: #{$pixel}px;
    margin-right: #{$pixel}px;
  }
  .-m-x-#{$pixel} {
    margin-left: -#{$pixel}px;
    margin-right: -#{$pixel}px;
  }
  .m-y-#{$pixel} {
    margin-top: #{$pixel}px;
    margin-bottom: #{$pixel}px;
  }
  .-m-y-#{$pixel} {
    margin-top: -#{$pixel}px;
    margin-bottom: -#{$pixel}px;
  }
  .p-a-#{$pixel} {
    padding: #{$pixel}px !important;
  }
  .p-x-#{$pixel} {
    padding-left: #{$pixel}px;
    padding-right: #{$pixel}px;
  }
  .p-y-#{$pixel} {
    padding-top: #{$pixel}px;
    padding-bottom: #{$pixel}px;
  }

  .font-size-#{$pixel} {
    font-size: #{$pixel}px;
  }

  .line-height-#{$pixel} {
    line-height: #{$pixel}px;
  }

  .min-width-#{$pixel} {
    min-width: #{$pixel}px;
  }

  .max-width-#{$pixel} {
    max-width: #{$pixel}px;
  }

  .width-#{$pixel} {
    width: #{$pixel}px;
  }

  .min-height-#{$pixel} {
    min-height: #{$pixel}px;
  }

  .max-height-#{$pixel} {
    max-height: #{$pixel}px;
  }

  .height-#{$pixel} {
    height: #{$pixel}px;
  }

  .top-#{$pixel} {
    top: #{$pixel}px;
  }
  .-top-#{$pixel} {
    top: -#{$pixel}px;
  }
  .bottom-#{$pixel} {
    bottom: #{$pixel}px;
  }
  .-bottom-#{$pixel} {
    bottom: -#{$pixel}px;
  }
  .right-#{$pixel} {
    right: #{$pixel}px;
  }
  .-right-#{$pixel} {
    right: -#{$pixel}px;
  }
  .left-#{$pixel} {
    left: #{$pixel}px;
  }
  .-left-#{$pixel} {
    left: -#{$pixel}px;
  }

  .z-index-#{$pixel} {
    z-index: #{$pixel};
  }

  .-z-index-#{$pixel} {
    z-index: -#{$pixel};
  }

  .square-#{$pixel} {
    width: #{$pixel}px;
    height: #{$pixel}px;
    min-width: #{$pixel}px;
    min-height: #{$pixel}px;
    max-width: #{$pixel}px;
    max-height: #{$pixel}px;
  }

  .circle-#{$pixel} {
    @extend .square-#{$pixel};
    border-radius: 50%;
  }

  .diamond-#{$pixel} {
    @extend .square-#{$pixel};
    rotate: 45deg;
  }
  @include respond-to(laptop) {
    .square-laptop-#{$pixel} {
      width: #{$pixel}px;
      height: #{$pixel}px;
      min-width: #{$pixel}px;
      min-height: #{$pixel}px;
      max-width: #{$pixel}px;
      max-height: #{$pixel}px;
    }

    .diamond-laptop-#{$pixel} {
      @extend .square-laptop-#{$pixel};
      rotate: 45deg;
    }

    .circle-laptop-#{$pixel} {
      @extend .square-laptop-#{$pixel};
      border-radius: 50%;
    }
  }

  $pixel: $pixel + 1;
}

$widthHeight: 0;
@while $widthHeight <= 100 {
  .v-width-#{$widthHeight} {
    width: #{$widthHeight}vw;
  }
  .v-min-width-#{$widthHeight} {
    min-width: #{$widthHeight}vw;
  }

  .v-height-#{$widthHeight} {
    height: #{$widthHeight}vh;
  }

  .p-width-#{$widthHeight} {
    width: #{$widthHeight} + "%";
  }

  .p-max-width-#{$widthHeight} {
    max-width: #{$widthHeight} + "%";
  }

  .p-height-#{$widthHeight} {
    height: #{$widthHeight} + "%";
  }

  .p-max-height-#{$widthHeight} {
    max-height: #{$widthHeight} + "%";
  }

  .v-min-height-#{$widthHeight} {
    min-height: #{$widthHeight}vh;
  }

  .v-max-width-#{$widthHeight} {
    max-width: #{$widthHeight}vw;
  }

  .p-left-#{$widthHeight} {
    left: #{$widthHeight} + "%";
  }
  .-p-left-#{$widthHeight} {
    left: -#{$widthHeight} + "%";
  }

  .p-right-#{$widthHeight} {
    right: #{$widthHeight} + "%";
  }
  .-p-right-#{$widthHeight} {
    right: -#{$widthHeight} + "%";
  }

  .border-radius-#{$widthHeight} {
    border-radius: #{$widthHeight} + "px";
  }

  $widthHeight: $widthHeight + 1;
}

.min-width-fit {
  min-width: fit-content;
}

.max-width-fit {
  max-width: fit-content;
}

.min-height-fit {
  min-height: fit-content;
}

.max-height-fit {
  max-height: fit-content;
}
.max-height-none {
  max-height: none;
}

.m-auto {
  margin: auto;
}

@each $name, $color in $colors {
  @each $side in $sides {
    .border-#{str-slice($side, 0, 1)}-#{$name} {
      border-#{$side}: 1px solid #{$color};
    }
    .border-2-#{str-slice($side, 0, 1)}-#{$name} {
      border-#{$side}: 2px solid #{$color};
    }

    .border-3-#{str-slice($side, 0, 1)}-#{$name} {
      border-#{$side}: 3px solid #{$color};
    }
  }
  .border-#{$name} {
    border: 1px solid #{$color};
  }

  .border-2-#{$name} {
    border: 2px solid #{$color};
  }
  .border-3-#{$name} {
    border: 3px solid #{$color};
  }
}

.border-none {
  border: none;
}

.border-transparent {
  border: transparent;
}

.list-style-none {
  list-style: none;
}

.space-letters {
  letter-spacing: 0.15em;
}

@each $name, $color in $colors {
  .color-#{$name} {
    color: #{$color};
  }

  .background-color-#{$name} {
    background-color: #{$color};
  }
}

.background-color-transparent {
  background-color: transparent;
}

.color-transparent {
  color: transparent;
}

.futura-reg {
  font-family: "Futura";
}

.futura {
  font-family: "Futura-Med";
}

.futura-cond {
  font-family: "Futura Condensed";
  letter-spacing: 0.15em;
  @extend .uppercase;
  @extend .color-blue;
}
.sentinel {
  font-family: "Sentinel";
  font-weight: 400;
}

.bold {
  font-weight: 600;
  letter-spacing: 0.05em;
}
// .thin {
//   font-weight: 100;
// }

// .extra-light {
//   font-weight: 200;
// }

// .light {
//   font-weight: 300;
// }

// .regular {
//   font-weight: 400;
// }

// .medium {
//   font-weight: 500;
// }

// .semi-bold {
//   font-weight: 600;
// }

// .bold {
//   font-weight: 700;
// }

// .extra-bold {
//   font-weight: 800;
// }

// .black {
//   font-weight: 900;
// }

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.underline {
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.strike-through {
  text-decoration: line-through;
}

.text-decoration-none {
  text-decoration: none;
}

.opacity-0 {
  opacity: 0;
}
.opacity-5 {
  opacity: 0.5;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-1 {
  opacity: 1;
}

.italic {
  font-style: italic;
}

.width-fit {
  width: fit-content;
}
.width-max {
  width: max-content;
}
.width-min {
  width: min-content;
}
.width-auto {
  width: auto;
}

.height-fit {
  height: fit-content;
}
.height-max {
  height: max-content;
}
.height-min {
  height: min-content;
}
.height-auto {
  height: auto;
}
.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}
// SPACING

.justify-center {
  justify-content: center;
  justify-items: center;
}
.justify-around {
  justify-content: space-around;
  justify-items: space-around;
}
.justify-between {
  justify-content: space-between;
  justify-items: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
  justify-items: space-evenly;
}
.justify-start {
  justify-content: flex-start;
  justify-items: flex-start;
}
.justify-end {
  justify-content: flex-end;
  justify-items: flex-end;
}

.align-start {
  align-content: flex-start;
  align-items: flex-start;
}
.align-center {
  align-content: center;
  align-items: center;
}
.align-around {
  align-content: space-around;
  align-items: space-around;
}
.align-between {
  align-content: space-between;
  align-items: space-between;
}
.align-evenly {
  align-content: space-evenly;
  align-items: space-evenly;
}
.align-end {
  align-content: flex-end;
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-direction-column {
  flex-direction: column;
}
.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.p-sticky {
  position: sticky;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.pointer-none {
  pointer-events: none;
}

.user-select-none {
  user-select: none;
}

.transition {
  transition: all 0.2s;
}

.transition-slow {
  transition: all 3s;
}

.highlight-none {
  -webkit-tap-highlight-color: transparent;
}

.hover {
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
}

.object-fit-contain {
  object-fit: contain;
}
.object-fit-cover {
  object-fit: cover;
}
.object-position-center {
  object-position: center;
}
.object-position-top {
  object-position: top;
}

$degrees: 0;
@while $degrees <= 360 {
  .rotate-#{$degrees} {
    rotate: #{$degrees}deg;
  }
  .-rotate-#{$degrees} {
    rotate: -#{$degrees}deg;
  }
  $degrees: $degrees + 1;
}

.box-shadow-none {
  box-shadow: none;
}

.dropdown-menu-box-shadow {
  box-shadow: 0px 4px 5px 2px rgb(0 0 0 / 25%);
}

.dropdown-item {
  &:hover {
    background-color: #0000001a;
  }
}

.center {
  @extend .d-flex;
  @extend .justify-center;
  @extend .align-center;
}

input {
  @extend .font-size-16;
  @extend .border-radius-0;
  @extend .color-brown;
  @extend .p-l-10;
  @extend .p-t-0;
  @extend .p-b-0;
  &::placeholder {
    @extend .color-brown;
    @extend .futura;
    @extend .font-size-16;
    opacity: 0.8;
  }
  &:focus-visible {
    outline: none;
    @extend .border-red;
  }
  &:hover {
    @extend .border-red;
  }
}

.thick-input {
  @extend .height-46;
}
.thin-input {
  @extend .height-36;
}

.button {
  @extend .color-white;
  @extend .p-width-100;
  @extend .font-size-16;
  @extend .futura;
  @extend .center;
  @extend .p-l-10;
  @extend .p-r-10;
  @extend .height-36;
  &:disabled {
    @extend .pointer-none;
    @extend .opacity-5;
  }
  @include respond-to(laptop) {
    height: 48px;
    font-size: 20px;
  }
}

.red-button {
  @extend .button;

  @extend .background-color-red;

  &:hover {
    @extend .background-color-hoverRed;
  }
}

.red-button-outline {
  @extend .button;
  @extend .border-2-red;
  @extend .color-red;

  &:hover {
    border: 2px solid var(--hoverRed);
    @extend .background-color-hoverWhite;
  }
}

.red-button-flat {
  @extend .button;
  @extend .color-red;
  @extend .background-color-white;

  &:hover {
    @extend .background-color-hoverWhite;
  }
}

.brown-button-outline {
  @extend .button;
  @extend .border-2-brown;
  @extend .color-brown;

  &:hover {
    border: 2px solid var(--hoverBrown);
    @extend .background-color-hoverWhite;
  }
}

.blue-button {
  @extend .button;

  @extend .background-color-blue;

  &:hover {
    @extend .background-color-hoverBlue;
  }
}

.link {
  @extend .border-none;
  @extend .underline;
  @extend .cursor-pointer;
}

.brown-link {
  @extend .link;
  @extend .color-brown;
  &:hover {
    @extend .color-hoverBrown;
    @extend .background-color-cream;
  }
}

.white-link {
  @extend .link;
  @extend .color-white;
}

.blue-link {
  @extend .link;
  @extend .color-blue;
}

.tag {
  padding: 2px 6px;
  @extend .font-size-14;
  @extend .futura;
}

.brown-tag {
  @extend .tag;
  @extend .color-white;
  @extend .background-color-brown;
}

.gold-tag {
  @extend .tag;
  @extend .color-black;
  @extend .background-color-gold;
}

.page-dot {
  @extend .circle-13;
  padding-left: 0;
  padding-right: 0;
  background-color: #d9d9d9;
  @include respond-to(laptop) {
    @extend .circle-laptop-20;
  }
}

.page-dot-selected {
  padding-left: 0;
  padding-right: 0;
  @extend .circle-13;
  @extend .background-color-blue;
  @include respond-to(laptop) {
    @extend .circle-laptop-20;
  }
}
