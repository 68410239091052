$weights: (
  "thin": 100,
  "light": 300,
  "regular": 400,
  "medium": 500,
  "bold": 700,
  "black": 900,
);

$colors: (
  "blue": var(--BLUE),
  "lightBlue": var(--LIGHT_BLUE),
  "green": var(--GREEN),
  "yellow": var(--YELLOW),
  "red": var(--RED),
  "tan": var(--TAN),
  "grey": var(--GREY),
  "white": var(--WHITE),
  "black": var(--BLACK),
);

$sides: (top, bottom, left, right);

@each $color, $hex in $colors {
  .#{$color}-color {
    color: #{$hex};
  }

  .#{$color}-background {
    background-color: #{$hex};
  }

  .#{$color}-border-1 {
    border: 1px solid #{$hex};
  }

  @each $side in $sides {
    .#{$color}-border-#{$side}-1 {
      border-#{$side}: 1px solid #{$hex};
    }
  }
}

@each $name, $weight in $weights {
  $size: 10;
  @while $size <= 48 {
    @each $color, $hex in $colors {
      .font-#{$size}-#{$name}-#{$color} {
        font-size: #{$size}px !important;
        font-family: "Colfax #{$name}" !important;
        color: #{$hex} !important;
      }
    }
    $size: $size + 2;
  }
}

.navLink {
  @extend .grey-background;
  @extend .lightBlue-border-left-1;
  @extend .lightBlue-border-right-1;
  @extend .lightBlue-border-top-1;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-left: 60px;
}
.navLink:last-child {
  @extend .lightBlue-border-bottom-1;
}
.activeNavLink {
  @extend .font-16-medium-blue;
  @extend .navLink;
}

.inactiveNavLink {
  @extend .font-16-medium-black;
  @extend .navLink;
}
