@import "./Helpers.scss";
@import "./Mixins.scss";
@import "./Pams.scss";

.App {
  padding-top: var(--small-headerHgt);
  height: calc(100vh - var(--small-headerHgt));

  text-align: center;
  @extend .futura;

  @include respond-to(laptop) {
    padding-top: var(--large-headerHgt);
    height: calc(100vh - var(--large-headerHgt));
  }
}

img {
  max-height: 100%;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  @extend .font-size-30;
}

#payment-form {
  iframe {
    display: flex;
  }
}

body {
  > iframe {
    display: none;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .sentinel;
  @extend .color-red;
}
p {
  @extend .color-brown;
}

a {
  cursor: pointer;
  text-decoration: underline;
}

em {
  @extend .color-brown;
}

button {
  background-color: transparent;
  outline: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  &:focus-visible {
    outline: 5px solid -webkit-focus-ring-color;
  }
}

ul {
  padding-left: 0;
  margin: 0;
}

li {
  list-style: none;
  @extend .color-brown;
}

textarea {
  border-radius: 0;
  resize: none;
  @extend .color-brown;
  @extend .futura;
  @extend .font-size-16;
  &::placeholder {
    opacity: 0.8;
    @extend .color-brown;
    @extend .futura;
  }
  &:focus-visible {
    outline: none;
    @extend .border-red;
  }
}

.MuiOutlinedInput-root {
  @extend .futura;
  @extend .color-brown;
  border-radius: 0 !important;
  height: 55px;
  border: 1px solid var(--brown);
  &:hover {
    border: 1px solid var(--red);
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.eapps-instagram-feed-posts-slider-nav-icon,
.eapps-instagram-feed-posts-slider-next,
.eapps-instagram-feed-posts-slider-prev {
  display: none;
  @include respond-to(laptop) {
    display: block;
  }
}

#cardigan_cart {
  h3 {
    @extend .sentinel;
    @extend .font-size-20;
    @extend .color-red;
    @extend .text-align-left;
  }
}

#tip_input {
  &::placeholder {
    @extend .font-size-18;
  }
}

#address-lookup {
  &:focus-visible {
    border: none;
  }
}

#animateDays {
  position: absolute;
  transition-timing-function: ease-in-out;
  transition-duration: 100ms;
  transition-property: left, right;
  left: 0;
  width: 100%;
}

.flickity-enabled:focus .flickity-viewport {
  outline: thin dotted;
  outline: 5px auto #d85028;
}

.carousel {
  .flickity-prev-next-button {
    display: none;
    @include respond-to(laptop) {
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        opacity: 0.7;
      }
      img {
        width: 55px;
      }
    }
    .next {
      right: 30px;
    }
    .previous {
      left: 30px;
    }
  }
}

.billboard-carousel {
  .flickity-prev-next-button {
    top: 85%;
  }
}

.products-carousel {
  .flickity-prev-next-button.previous {
    left: 0px;
  }

  .flickity-prev-next-button.next {
    right: 0px;
  }
}

.sub-category-carousel {
  .flickity-prev-next-button.previous {
    left: 20px;
  }
  .flickity-prev-next-button.next {
    right: 20px;
  }
}

.sticky-popup {
  position: sticky;
  z-index: 250;
  background-color: white;
  padding-left: 30px !important;
  width: calc(100% + 30px) !important;
  margin-left: -30px !important;
}
.popup-button {
  @extend .sticky-popup;
  bottom: 0px;
  padding-bottom: 20px !important;
}

.popup-header {
  @extend .sticky-popup;
  top: 0px;
}

.popup-button-gradient {
  position: sticky;
  z-index: 250;
  bottom: 0px;
  padding: 30px 0px !important;
  margin: 0 0px !important;
  width: calc(100% + 80px) !important;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 25%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 0.75) 75%,
    rgba(255, 255, 255, 1) 100%
  );
  @include respond-to(laptop) {
    padding: 30px 40px !important;
    margin: 0 -40px !important;
  }
}

#job-description-popup {
  li {
    list-style: disc;
  }
}
