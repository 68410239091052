@font-face {
  font-family: Futura Condensed ;
  src: local("Futura Condensed"), url(../fonts/FuturaStd-Condensed.otf) format("opentype");
}
@font-face {
  font-family: "Futura";
  src: local("Futura"), url(../fonts/FuturaStd-Medium.otf) format("opentype");
}

@font-face {
  font-family: "Futura-Med";
  src: local("Futura"), url(../fonts/Futura-Medium-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Sentinel";
  src: local("Sentinel"), url(../fonts/Sentinel-Bold.otf) format("opentype");
}

html {
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  scroll-behavior: smooth;
  
}

#root {
  height: 100vh;
}

/* width */
/* ::-webkit-scrollbar {
  width: 20px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #b30000;
} */