$breakpoints: (
  "phone": (
    min-width: 0px,
  ),
  "tablet": (
    min-width: 640px,
  ),
  "laptop": (
    min-width: 1024px,
  ),
  "desktop": (
    min-width: 1200,
  ),
  "lgDesktop": (
    min-width: 1536,
  ),
) !default;

/// Mixin to manage responsive breakpoints
/// @author Kitty Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
